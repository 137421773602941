import HomeIcon from "./HomeIcon";
import SearchBar from "./SearchBar";
import Title from "./Title";
import HelpIcon from "./HelpIcon";
import TextVersionIcon from "./TextVersionIcon";

import './Header.css';

const Header = ({ id }) => {

    return (
        <>
            <div className='header'>
                <HomeIcon />
                <SearchBar />
                <Title />
                <TextVersionIcon id={id} />
                <HelpIcon />
            </div>
        </>
    )
};

export default Header;