import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useFetchJson } from '../hooks/useFetchJson';
import { FETCH_PATH } from '../utils/Environment';

import Header from '../components/Header';
import SidePanel from '../components/SidePanel';
import ScorecardGrid from '../components/ScorecardGrid';
import Legend from '../components/Legend';
import Footer from '../components/Footer';
import DetailPanel from '../components/DetailPanel'

import './Scorecard.css';

const Scorecard = () => { 

  // fetch id from route parameters
  const { id } = useParams();
  
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const version = params.get('version');
  const schema = params.get('schema');
  const vendor = params.get('vendor');
  
  // TODO: use url builder?
  const [ url ] = useState(`${FETCH_PATH}/scorecard/${id}?version=${version}&schema=${schema}&vendor=${vendor}`
      .replace('version=null','')
      .replace('schema=null', '')
      .replace('vendor=null', '')
      .replaceAll('&&', '&')
      .replace('?&', '?')
      .replace(/\?$/, '')
      .replace(/\&$/, ''));
  
  const { data, isPending, error } = useFetchJson(url);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
      if (data) {
          const flat = data.scorecards.concat(data.scorecards.flatMap(c => c.children || []));
          setSelectedItem(item ? flat.filter(a => a.code === item)[0] : null);
      }
  };

  return (

    <>
      <div className='fullpage'>
        <Header id={id} />
        <div className='content'>
          <SidePanel data={data} isPending={isPending} error={error} />
          <div className='inner-content'>
            <ScorecardGrid data={data} isPending={isPending} error={error} onItemClick={handleItemClick} />
            <DetailPanel item={selectedItem} onClose={() => {handleItemClick(null);}} />
            {data && !isPending && <Legend />}
          </div>          
        </div>
        <Footer />
      </div>
    </>
    
  );
};

export default Scorecard;
