import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FETCH_PATH } from '../utils/Environment';

import Tooltip from '@mui/material/Tooltip';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import './TextVersionIcon.css';

const TextVersionIcon = ({ id }) => {

    const [loading, setLoading] = useState(false);

    const onLinkClick = async () => {

        // show progress spinner
        setLoading(true);

        try {

          // fetch file
          const response = await fetch(`${FETCH_PATH}/scorecard/${id}?export=true`, { 
            credentials: 'include',
          });

          // determine contents, filename, and content type
          const file_contents = await response.text();
          const file_name = await response.headers.get('Content-Disposition')
            .split(';')
            .find(n => n.includes('filename='))
            .replace('filename=', '')
            .trim()
          ;
          const content_type = await response.headers.get('Content-Type');

          // create blob and URL
          const blob = new Blob([file_contents], { type: content_type });
          const url = URL.createObjectURL(blob);

          // create link and trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = file_name;
          link.click();
        }
        catch (error) {

          console.error(error);
        }
        finally {

          setLoading(false);
        }
    };

    return (
        <>
            <div className='textversionicon'>
                { id &&
                    <Tooltip title='Download a textual version of this Scorecard'>
                        
                            {loading ? <CircularProgress size={20} /> : <Link onClick={onLinkClick} href='#'><FileDownloadOutlinedIcon /></Link>}
                        
                    </Tooltip>
                }
            </div>
        </>
    )
};

export default TextVersionIcon;